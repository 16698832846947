export default function HoverCard({ children }){
    return(
        <div className="card-hover-container">
            <div className="card-hover" />
            <div className="card-hover" />
            <div className="card-hover" />
            <div className="card-hover" />
            <div className="card-hover" />
            <div className="card-hover" />
            <div className="card-hover" />
            <div className="card-hover" />

            <div className="card-contents" style={{ zIndex: '0'}}>
                {children}
            </div>  
        </div>
    )
}