import './App.scss';
import './index.css';
import './misc.css'
import { BrowserRouter as Router, Link, Navigate, Route, Routes} from 'react-router-dom';

// pages
import AboutMePage from './pages/AboutMePage';
import BlankPage from './pages/BlankPage';
import Sidebar from './layout/Sidebar';


function App() {
  return (
    <>
      <Router>
        {/* <Sidebar /> */}

        <Routes>
          <Route path="*" exact element={<Navigate to="/aboutme" />} />
          <Route path="/aboutme" exact element={<AboutMePage />} />
          <Route path="/testing" exact element={<BlankPage />} />

          <Route path="*" element={<Navigate to="/aboutme" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
