import { useEffect, useRef, useState } from 'react';
import { Button, Container, Stack } from '@mui/material';
import { gsap, Power3 } from 'gsap';



function BlankPage() {
  return (
    <>
      ANJAY
    </>
  );
}

export default BlankPage;
