import { useEffect, useRef, useState } from 'react';
import { Button, Container, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { gsap, Power3 } from 'gsap';

import Confetti from 'react-dom-confetti';
import SplitTextJs from 'split-text-js';
import HoverCard from "../components/elements/HoverCard";

// ASSETS
import cross from '../assets/img/cross.svg';
import InstagramIcon from '../assets/img/ic_instagram.svg';
import LinkedInIcon from '../assets/img/ic_linkedin.svg';

const confettiConfig = {
  angle: 90,
  spread: 400,
  startVelocity: 40,
  elementCount: "120",
  dragFriction: 0.12,
  duration: 8000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "700px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

const heroAnimationSpeed = 0; // 0.7

function HomePage() {
  const theme = useTheme();
  const isTablet =  useMediaQuery(theme.breakpoints.up('sm'));

  let introHeroSectionRef = useRef();
  const [popConfetti, setPopConfetti] = useState(false);
  const [heroLoaded, setHeroLoaded] = useState(false);

  const handleHeroEntry = () => {
    const heroHello = document.querySelector('.intro-hero-hello');
    const heroHelloHello = document.querySelector('.intro-hero-hello-hello');
    const imHello = document.querySelector('.intro-hero-hello-im');

    const topHeaderText = document.querySelector('.top-header');
    const splittedTopHeaderText = new SplitTextJs(topHeaderText);

    const designerText = document.querySelector('.role-designer');
    const splittedDesignerText = new SplitTextJs(designerText);

    const crossIcon = document.querySelector('.role-cross')

    const developerText = document.querySelector('.role-developer');
    const splittedDeveloperText = new SplitTextJs(developerText);

    let tl = gsap.timeline();

    const creativeHeader = document.querySelector('.creative-header');

    const introHeroCircle = document.querySelector('.intro-hero-circle');
    
    tl.to([heroHello, heroHelloHello, imHello], {
      opacity: 1,
      duration: 1 * heroAnimationSpeed,
      stagger: 0.8 * heroAnimationSpeed,
      y: -40,
      ease: Power3.easeOut,
      fontSize: '4rem'
    }).to(heroHello, {
      duration: 1 * heroAnimationSpeed,
      delay: 0.5 * heroAnimationSpeed,
      top: '9rem',
      left: '2rem',
      ease: Power3.easeInOut,
    }).to([imHello, heroHelloHello], {
      fontSize: '2rem',
      duration: 0.6 * heroAnimationSpeed,
      delay: -1.5 * heroAnimationSpeed,
      ease: Power3.easeInOut,
      stagger: 0.2 * heroAnimationSpeed,
    }).from([splittedTopHeaderText.chars, creativeHeader, splittedDeveloperText.chars, crossIcon, splittedDesignerText.chars], {
      opacity: 0,
      duration: 0.8 * heroAnimationSpeed,
      stagger: 0.025 * heroAnimationSpeed,
      ease: Power3.easeOut,
      y: 80,
    }).add(function(){
      setPopConfetti(true);
    }).fromTo(introHeroCircle, {
      opacity: 0,
      width: 0,
      height: 0,
      borderRadius: '100%'
    }, {
      duration: 1.2 * heroAnimationSpeed,
      opacity: 1,
      width: '150vw',
      height: '150vh',
      ease: Power3.easeOut,
      borderRadius: '0%'
    }).fromTo(introHeroSectionRef, {
      backgroundColor: '#FFFFFF'
    }, {
      backgroundColor: '#dddddd',
      duration: 0.8 * heroAnimationSpeed,
      ease: Power3.easeOut
    }).to(heroHello, {
      color: '#FFFFFF',
      delay: -2 * heroAnimationSpeed
    }).to([developerText, crossIcon, designerText],{
      color: '#FFFFFF',
      mixBlendMode: 'unset',
      delay: -1.8 * heroAnimationSpeed
    }).add(function(){
      setHeroLoaded(true);
    }, 6.5 * heroAnimationSpeed)
  }

  useEffect(() => {
    handleHeroEntry();

    gsap.set('.cursor, .cursor-big', {
      xPercent: -50,
      yPercent: -50
    });
    
    // const cursor = document.querySelector('.cursor')
    // const cursorBig = document.querySelector('.cursor-big')
    
    // window.addEventListener('mousemove', function(e){
    //   gsap.to(cursor, {
    //     x: e.clientX,
    //     y: e.clientY,
    //     duration: .1
    //   })
    //   gsap.to(cursorBig, {
    //     x: e.clientX,
    //     y: e.clientY,
    //     duration: .3
    //   })
    // })
  }, []);

  return (
    <>
      <section id="intro-hero-section" ref={el => introHeroSectionRef = el}>
        <Container maxWidth='xl' style={{ height: '100%'}}>
          <Stack className="intro-hero-stack" justifyContent="center" alignItems="center">
            <Stack className="intro-hero-hello" direction="column">
              <h2 className="intro-hero-hello-hello">
                Hello <span className="handwave-emoji">👋</span>
              </h2>
              <h2 className="intro-hero-hello-im" style={{ marginTop: '0.1rem'}}>
                I'm Kelvin Adithya,
              </h2>
            </Stack>

            {heroLoaded &&
              <div className="hero-ka-logo-container">
                <object type="image/svg+xml" data='/static/img/logo/ka-logo-white-animated.svg'>svg-animation</object>
              </div>
            }
            

            <Stack style={{ marginTop: '5rem'}}>
              <div style={{ height: '0px', width: '0px', position: 'relative', marginLeft: 'auto', marginRight: 'auto', zIndex: '100'}}>
                <Confetti active={ popConfetti } config={ confettiConfig } />
              </div>

              <h3 className="top-header" style={{ fontWeight: '400', mixBlendMode: 'difference', zIndex: '3', textAlign: 'center' }}>
                you can considers myself as a
              </h3>

              <h1 className="creative-header" style={{ zIndex: '3' }}>
                Creative
              </h1>
              <div className="intro-hero-circle-container">
                <div className="intro-hero-circle" />
              </div>

              <Stack direction="row" className="role-header" spacing={2} alignItems="center" justifyContent="center" >
                <h2 className="role-developer">[Developer]</h2>
                <img src={cross} alt="X" className="role-cross" />
                <h2 className="role-designer">Designer.</h2>
              </Stack>
            </Stack>

            <div className="hero-bg-line" style={{ zIndex: '1', marginTop: '5rem' }}>
              <div className="bg-line-lines line-1" style={{ marginTop: '4rem', width: '70%' }} />
              <div className="bg-line-lines line-2" style={{ marginTop: '8rem', width: '30%' }} />
              <div className="bg-line-lines line-3" style={{ marginTop: '12rem', width: '20%' }} />
              <div className="bg-line-lines line-4" style={{ marginTop: '16rem', width: '10%' }} />
              <div className="bg-line-lines line-5" style={{ marginTop: '20rem', width: '5%' }} />
            </div>
          </Stack>
        </Container>
      </section>

      <section id="introduction-section" style={{ display: heroLoaded ? 'block' : 'none' }}> 
        <div className='blur-dot-bg'>
        <div className="blur-dot-red" />
        <div className="blur-dot-purple" />
        <div className="blur-dot-yellow" />
        </div>

        <Container maxWidth='xl' style={{ height: '100%', position: 'relative', paddingTop: '4rem' }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack alignItems="center">
                <HoverCard>
                  <Stack className="blur-card p-relative">
                    {isTablet &&
                      <img src="/static/img/photo/me.jpg" className='my-photo' alt="" style={{ width: '100%', maxWidth: '12rem', position: 'absolute', right: '2rem', top: '-2rem', zIndex: '3' }} />
                    }

                    <h2 className="pill-text yellow">Little things</h2>
                    <h1 style={{ fontSize: '2.4rem', margin: 0, marginTop: '0.4rem'}}>
                      About Me
                    </h1>

                    <div className="hr-line" />

                    {!isTablet &&
                      <img src="/static/img/photo/me.jpg" className='my-photo' alt="" style={{ width: '100%', maxWidth: '12rem', zIndex: '3', marginInline: 'auto', marginTop: '1rem', marginBottom: '1.4rem' }} />
                    }

                    <p style={{ marginTop: '0.2rem', maxWidth: '40rem'}}>
                      Hi! I'm a 22 y.o Indonesian <span role="img" style={{ marginRight: '0.5rem'}}>🇮🇩</span>
                      teenager that loves 
                      <br/>
                      <span style={{ fontSize: '1.2rem', fontWeight: '600'}}>
                        Programming 👨🏻‍💻 & Design 🎨
                      </span>
                      <br/><br/>
                        I enjoy the pursuit of craft and fiddling with technology. Currently working as a freelance frontend developer in the crypto world, made a lot of websites from ground-up mainly using ReactJS and NodeJS.
                      <br/><br/>
                        You can assume I'm pretty good at web development ✌️.
                    </p>

                    <h2 style={{ fontSize: '1.4rem', margin: 0, marginTop: '2rem'}}>
                      Skills and Tools
                    </h2>

                    <Stack>
                      <Stack spacing="0.4rem" style={{ marginTop: '1rem' }}>
                        <h3>
                          Frontend
                        </h3>
                        <div>
                          HTML, CSS, JS, React, Angular, Vue, GSAP
                        </div>
                      </Stack>

                      <Stack spacing="0.4rem" style={{ marginTop: '1rem' }}>
                        <h3>
                          UI/UX
                        </h3>
                        <div>
                          XD, Figma, Affinity Designer
                        </div>
                      </Stack>

                      <Stack spacing="0.4rem" style={{ marginTop: '1rem' }}>
                        <h3>
                          Backend
                        </h3>
                        <div>
                          NodeJS, Spring MVC, Laravel
                        </div>
                      </Stack>

                      <Stack spacing="0.4rem" style={{ marginTop: '1rem' }}>
                        <h3>
                          CI/CD
                        </h3>
                        <div>
                          Docker, Kubernetes, OpenShift
                        </div>
                      </Stack>

                      <Stack spacing="0.4rem" style={{ marginTop: '1rem' }}>
                        <h3>
                          Database
                        </h3>
                        <div>
                          SQL (MySQL, PostgreSQL, Oracle), Redis
                        </div>
                      </Stack>

                      <Stack spacing="0.4rem" style={{ marginTop: '1rem' }}>
                        <h3>
                          Language
                        </h3>
                        <div>
                          Javascript, Typescript, Java, PHP
                        </div>
                      </Stack>
                    </Stack>
                  </Stack>
                </HoverCard>

                <button className='text_button' style={{ marginInline: 'auto', marginTop: '1rem', marginBottom: '8rem' }} onClick={(event) => window.open('https://www.linkedin.com/in/kelvinadithya/', '_blank')}>
                  <img className="btn-icon" src={LinkedInIcon} alt="In" />
                </button>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
}

export default HomePage;
